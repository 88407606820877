/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;	
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img {
  max-width: 100%;
}

:root {
  --bg-white: #ffffff;
  --bg-blue: #004B89;
  --bg-gray: #4F4F4F;
  --bg-black: #000000;
  --color4: #E3FDFD;
  --color3: #CBF1F5;
  --color2: #A6E3E9;
  --color1: #71C9CE;
}

.btn-outline-primary {
  --bs-btn-color: #004B89;
  --bs-btn-border-color: #004B89;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #004B89;
  --bs-btn-hover-border-color: #004B89;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004B89;
  --bs-btn-active-border-color: #004B89;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #004B89;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #004B89;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #EB5757;
  --bs-btn-border-color: #EB5757;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #EB5757;
  --bs-btn-hover-border-color: #EB5757;
  --bs-btn-focus-shadow-rgb: 220,53,69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #EB5757;
  --bs-btn-active-border-color: #EB5757;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #EB5757;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EB5757;
  --bs-gradient: none;
}

.text-blue{
  color: #2F80ED;
}
.text-green{
  color: #27AE60;
}
.text-red{
  color: #EB5757;
}
.text-black{
  color: #000000;
}
.bg-blue{
  background-color: #2F80ED;
}
.bg-green{
  background-color: #27AE60;
}
.bg-red{
  background-color: #EB5757;
}


html, body {
  height: 100%;
}

body{
  -webkit-font-smoothing: antialiased;
  font-family: 'Work Sans', sans-serif;
  background: #F5F5F5;  
}
#root{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page{
 padding-top: 5rem;
 margin-bottom: 20px;
}

.navBar {
  height: 5rem;
  z-index: 2;
}
.navBar.bg-light {
  background-color: var(--bg-white) !important;
}
/* .navBar div button {
  background-color: var(--color2) !important;
} */
.sideNavBar {
  background-color: var(--color3) !important;
}

.logo-main{
  margin: 0;
}
.logo-main img{
  height: 50px;
}

.user-dropdown{  
  text-align: center;
}
.user-dropdown a {
  /* display: flex; */
}
.user-dropdown a img{  
  width: 30px;
  height: 30px;
}
.dropdown-toggle::after{
  display: none;
}
.dropdown-arrow{
  background: url(assets/images/dropdown-arrow.png) no-repeat 0 0;
  width: 23px;
  height: 20px;
  display: block;
  position: absolute;
  top: -13px;
  right: 13px;
  z-index: -1;
}
.user-dropdown-menu{
  width: 290px;
  border-radius: 0px 0px 10px 10px;
  border: 0;
  background-color: var(--bg-blue);
  font-size: 16px;
  padding: 0;
  top: 72px !important;
  right: 0;
  left: auto !important;
}
.user-dropdown-menu li{
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 15px;

}
.user-dropdown-menu li:last-child{
  border: 0;
}
.user-dropdown-menu .dropdown-item{
  background: url(assets/images/user-nav-arrow.png) no-repeat 0 center;
  color: var(--bg-white);
  font-weight: 500;
}
.user-dropdown-menu .dropdown-item:hover{
  background-color: transparent;
}

.user-dropdown-menu .user-info{
  display: flex;
  align-items: center;
  background: #2F80ED;
  padding: 8px;
  color: #fff;
}
.user-name {
  margin-left: 20px;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
}
.user-name:hover {
  color: #fff;
}

.map-area{
  /* height: calc(100vh - 180px); */  
}
.map-area img{
  max-width: 100%;
  aspect-ratio: 2/1;
}

.card{
  box-shadow: 0px 4px 20px 3px rgb(0 0 0 / 10%);
  border-radius: 10px;
  border: 0;
}
.card-header {
  background: transparent;
  min-height: 50px;
  border: 0;
  padding: 15px 20px;
}
.card-header-title{
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
}

footer {
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: var(--bg-blue);
}
footer .nav-link{
  color: var(--bg-blue);
}

.mapmarker-head{
  margin: 30px 0;
}
.mapmarker-head h4{
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #001AFF;
}
.last-update {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #001AFF;
  margin-bottom: 6px;
}
.mapmarker-list {  
  margin-bottom: 30px;
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
}
.mapmarker-list li{
  background: url(assets/images/list-icon.png) no-repeat 0 5px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  border-top: 1px solid #D9D9D9;
  padding: 5px 0 5px 30px;
}
.mapmarker-list li .title {
  margin-right: 25px;
}
.mapmarker-list li .amount{ 
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #000000;
}
.mapmarker-bodycontent .more-info{     
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  color: #001AFF;
}
.gm-style .gm-style-iw-c {
  border: 2px solid #001AFF;
  background: #FAFCFE;
}

.project-page-head{
  position: relative;
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin: 3px 0 20px 0;
}
.sub-head {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #828282;
  padding: 0 28px;
}
.project-title{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #333333;
  padding: 0 28px;
}
.project-dashboard{

}
.box-content{
  padding: 10px 20px;
  height: 100%;
}

.box-content .box-title{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  margin-bottom: 8px;
}
.site-name{
  font-family: 'Work Sans';
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.02em;
}
.site-section-name{
  font-family: 'Work Sans';
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-top: 10px;
  letter-spacing: -0.02em;
}
.time, .person-name{
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000000;
}
.valve-status{
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: normal;
  border-radius:10px;
  padding-left:10px;
  color: #fff;
}
.valve-on{
  background-color: #64a16d;
}
.valve-off{
  background-color: #e15555;
}
.valve-inprocess{
  background-color: #d4d474;
}
.valve-error{
  background-color: #676767;
}

.icon-time{
  background: url("assets/images/icon-clock.svg") no-repeat 0 0;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.icon-user{
  background: url("assets/images/icon-user.svg") no-repeat 0 0;
  width: 33px;
  height: 32px;
  margin-right: 10px;
}

.advanced-info{
  background: var(--bg-blue);
  height: 100%;
}
.advanced-info .panel_content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.operation-tracker-card{
  box-shadow: none;
  border-radius: 0;
}

/* table css start */
.card-header-top{
  display: flex;  
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  margin: 15px 0;
}
.card-header-top .card-header-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #333333;
}
.card-header-top .time-log {
  font-size: 14px;
}
.card-header-bottom{
  display: flex;
  justify-content: space-between;
}
.entry-limit{
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #BDBDBD;
  gap: 15px;
}
.entry-limit .form-control {
  width: 75px;
  height: 42px;
}
.search-box{
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #BDBDBD;
}
.form-control{
  border: 1px solid #BDBDBD;
  border-radius: 0;
}
.pump-detial {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.pump-detial li{
  display: flex;
  gap: 15px;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #000000;
}
.pump-detial span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 32px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-indent: 2px;
  color: #FFFFFF;
}
.table tr:last-child td{
  border-bottom: 3px solid #000000;
}
.table th{  
  padding: 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #333333;
  text-align: center;
  border: 3px solid #000000;
  border-width: 3px 0;
}
.table td{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #4F4F4F;
  height: 60px;
  vertical-align: middle;
}
.table_cl{
  text-align: left !important;
}
.total-entry{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #BDBDBD;
}
.pagination .page-item .page-link{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #BDBDBD;
  border-width: 0 0 0 1px;
}
.pagination .page-item:first-of-type .page-link {
  border-left: 0;
}

/* table css end */

.modal-content{
  border: 10px solid var(--bg-blue);
  border-radius: 15px;
}
.modal-backdrop {
  background-color: transparent;
}

.model-bg{
  background-color: var(--bs-backdrop-bg);
}

.btn-outline-dark:hover{
  background-color: var(--bg-blue) !important;
}

.list-group{
  height: calc(100vh - 350px);
  overflow-y: auto;
}

/*  Toggle Switch  */
.onoffswitch {
  position: relative;
  width: 58px;
  text-align: initial;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 3px solid #EB5757;
  border-radius: 20px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 21px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "ON";
  padding-left: 5px;
  background-color: #FFFFFF;
  color: #27AE60;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 5px;
  background-color: #FFFFFF;
  color: #EB5757;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 15px;
  height: 15px;
  margin: 4px;
  background: #EB5757;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 32px;
  transition: all 0.3s ease-in 0s; 
  border-radius: 50%;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
  border-color: #27AE60; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 2px; 
  background-color: #27AE60; 
}

/*  Toggle Switch  */
.opencloseswitch {
  position: relative;
  width: 72px;
  text-align: initial;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select: none;
}
.opencloseswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.opencloseswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 3px solid #EB5757;
  border-radius: 20px;
}
.opencloseswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.opencloseswitch-inner:before, .opencloseswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 21px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.opencloseswitch-inner:before {
  content: "OPEN";
  padding-left: 5px;
  background-color: #FFFFFF;
  color: #27AE60;
}
.opencloseswitch-inner:after {
  content: "CLOSE";
  padding-right: 5px;
  background-color: #FFFFFF;
  color: #EB5757;
  text-align: right;
}
.opencloseswitch-switch {
  display: block;
  width: 15px;
  height: 15px;
  margin: 4px;
  background: #EB5757;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 45px;
  transition: all 0.3s ease-in 0s; 
  border-radius: 50%;
}
.opencloseswitch-checkbox:checked + .opencloseswitch-label .opencloseswitch-inner {
  margin-left: 0;
}
.opencloseswitch-checkbox:checked + .opencloseswitch-label {
  border-color: #27AE60; 
}
.opencloseswitch-checkbox:checked + .opencloseswitch-label .opencloseswitch-switch {
  right: 2px; 
  background-color: #27AE60; 
}


/*  Toggle Switch  */
.autoManualswitch {
  position: relative;
  width: 72px;
  text-align: initial;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select: none;
}
.autoManualswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.autoManualswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 3px solid #EB5757;
  border-radius: 20px;
}
.autoManualswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.autoManualswitch-inner:before, .autoManualswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 21px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.autoManualswitch-inner:before {
  content: "Auto";
  padding-left: 5px;
  background-color: #FFFFFF;
  color: #27AE60;
}
.autoManualswitch-inner:after {
  content: "Manual";
  padding-right: 5px;
  background-color: #FFFFFF;
  color: #EB5757;
  text-align: right;
}
.autoManualswitch-switch {
  display: block;
  width: 15px;
  height: 15px;
  margin: 4px;
  background: #EB5757;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 45px;
  transition: all 0.3s ease-in 0s; 
  border-radius: 50%;
}
.autoManualswitch-checkbox:checked + .autoManualswitch-label .autoManualswitch-inner {
  margin-left: 0;
}
.autoManualswitch-checkbox:checked + .autoManualswitch-label {
  border-color: #27AE60; 
}
.autoManualswitch-checkbox:checked + .autoManualswitch-label .autoManualswitch-switch {
  right: 2px; 
  background-color: #27AE60; 
}

.add-site-form{  
  margin: 0 auto;
  padding: 20px 0;
}
.add-site-form .form-control,
.add-site-form .form-select {
  border: 1px solid #004B89;
  border-radius: 4px;
}
.add-site-form .col-form-label {
  font-weight: 600;
  color: #828282;
}

.nav-item{
  /* border-width: 1px;
  border-right-color: #dddddd;
  border-right-style: double; */
  padding-right: 15px;
  padding-left: 15px;
}
.add-site-tabs .nav-item{
  margin-right: 7px;
  border-width: 1px;
  border-right-color: #333333;
  padding: 0;
  border: 0;
}
.add-site-tabs .nav-item .nav-link{
  box-shadow: 0px 4px 20px 3px rgb(0 0 0 / 10%);
  border-radius: 15px 15px 0 0;
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #828282;
}
.add-site-tabs .nav-item .nav-link.active{
  background: #004B89;
  color: #fff;
}

.admin-nav{
  box-shadow: 0px 4px 20px 3px rgb(0 0 0 / 10%);
  background: #fff;
  margin: 3px 0 0px 0;
}

.loaderStyle{font-size: 16px; margin-left: 15px; color: gray; line-height: 20px;}
.loadingOpacity{opacity: 0.5;}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .nav-item {
    border-width: 1px;
    border-right-color: #dddddd;
    border-right-style: double;
  }
  .add-site-form .col-form-label{
    text-align: right;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .page{
    padding-top: 6.25rem;
  }
  .navBar {
    height: 6.25rem;
  }
  .logo-main img{
    height: 4.6875rem;
  }
  .user-dropdown a img{  
    width: 3rem;
    height: 3rem;
  }
  .box-content{
    padding: 20px 30px;
  }
  .box-content .box-title{
    font-size: 18px;
    line-height: 26px;
  }
  .box-content .box-subtitle{
    color: #cccccc;
    font-size: 16px;
  }
  .site-name{
    font-size: 26px;
    line-height: 36px;
  }
  .time, .person-name{
    font-size: 20px;
  }
  .project-btn-group .btn{ 
    min-width: 134px; 
  }
  .pump-detial{
    flex-direction: row;
  }
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .card-header-top{
    flex-direction: row;
  }
  .pump-detial span{
    width: 100px;
    font-size: 34px;
    height: 42px;
    line-height: 40px;
  }
  .add-site-tabs .nav-item .nav-link{
    padding: 20px;
    font-size: 18px;
  }
  .add-site-form{
    width: 50%;
  }
  .add-site-form {
    padding: 50px 0;
  }
 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .project-dashboard .box-content{
    border-left: 1px solid #dee2e6;
  }
  .project-dashboard .box-content.first{
    border-left: 0;
  }
 }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {  }

.scroll::-webkit-scrollbar {
  width: 0.4375rem;
}
.scroll::-webkit-scrollbar-thumb {
  min-height: 3.875rem;
  background: #a5a5a5;
  border-radius: 100vw;
}
.scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--bg-black);
}
.panel_contentmqttdata h2 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: -0.02em;
  /* color: #FAFCFE; */
  margin-top: 10px;
  margin-bottom: 10px;
}
.btnMarginRight {
  margin-right: 10px;
}
.checkboxLbl{
  font-style: italic;
  font-size: 14px;
  color:#737373;
}

.form-check-input{
  width: 16px;
  height: 16px;
  margin-top: 0;
}
.form-check-input:checked[type=checkbox]{
  background-size: 16px 16px;
}


.form-select{
  border-radius: 0;
}
.fs-7{
  font-size: 14px;
}
.pupmactivity-header{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
/* .scroll {

	&::-webkit-scrollbar {
		width: 0.4375rem;
	}

	&::-webkit-scrollbar-thumb {
		min-height: 3.875rem;
		background: #a5a5a5;
		border-radius: 100vw;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: variables.$orange-primary;
	}
} */

