@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap");
::root {
  --animationDistance: -100px;
  --animationDuration: 12s;
}
.wrapper {
  width: 85%;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  &:before {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: '';
    background: linear-gradient(
      90deg,
      #fff,
      transparent 25px,
      transparent calc(100% - 25px),
      #fff
    );
  }
}

.inner {
  animation-duration: var(--animationDuration);
  animation-timing-function: linear;
}

.inner.moving {
  animation-name: moveticker;
}

.element {
  display: inline-block;
  padding: 0 20px;
  font-family: 'Quicksand';
}

@keyframes moveticker {
  0% {
    transform: translateX(0px);
  }
  
  100% {
    transform: translateX(var(--animationDistance));
  }
}