.blob {
	box-shadow: 0 0 0 0 rgb(21, 64, 221);
	transform: scale(1);
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
	animation: pulse 3s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 2px rgba(51, 30, 205, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}