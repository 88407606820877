@keyframes canvasPop {
  100%{
      width: 23.75rem;
  }
}
@keyframes canvasPop_right {
  100%{
    width: 23.75rem;
  }
}
@keyframes canvasPop_top {
  100%{
      height: 13.5625rem;
  }
}

.left_canvas_pop_bg {
  width: 260px;
  /* height: 100vh; */
  display: flex;
  /* align-items: center; */
  z-index: 2;
  /* justify-content: center; */
  position: fixed;  
  left: -260px;
  transition: all .3s ease-in-out;
}
.left_canvas_pop_bg.show {
  left: 0;
}

.left_canvas_body {
  display: flex;  
  flex-direction: column;
  /* height: 100vh;   */
  background: var(--bg-blue);
  border-radius: 0 15px 15px 0;
  animation: canvasPop 0.3s linear forwards;
}
.arrow {
  background: var(--bg-gray);
  display: flex;
  justify-content: center ;
  align-items: center;
  height: 5rem;
  width: 1.5rem;
  border-radius: 0 10px 10px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  right: -24px;
  transform: translateY(-50%);

}
.arrow img {
  transform: rotate(270deg);
  transition: transform 0.5s linear;
}
.active_arrow_left {
  transform: rotate(90deg) !important;
  transition: transform 0.5s linear;
}
.panel_content{
  padding: 20px;
  height: 100%;
}
.panel_content h2{
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #FAFCFE;
  margin-bottom: 10px;
}
.basicinfo-list {
  width: 100%;
  margin-bottom: 30px;
  height: calc(100vh - 14rem);
  overflow-y: auto;
}
.basicinfo-list li{
  background: url(../../../src/assets/images/list-icon.png) no-repeat 0 12px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #56CCF2;
  border-bottom: 1px solid rgb(255,255,255,0.2);
  padding: 10px 0 10px 30px;
}
.basicinfo-list li .title {
  width: 65%;
}
.basicinfo-list li .amount{ 
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}
.panel_content .more-info{
  background: url(../../../src/assets/images/right-arrow.svg) no-repeat right 6px;
  padding-right: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-decoration-line: underline;
  color: #FFFFFF;
}
.panel_content .more-info:hover{ 
  text-decoration-line: none;
  color: #FFFFFF;
}
.right_canvas_pop_bg {
  width: 260px;
  /* height: 100vh; */
  display: flex;
  right: -260px;
  background: transparent;
  /* align-items: center; */
  z-index: 2;
  /* justify-content: center; */
  position: fixed;
  transition: all .3s ease-in-out;
}
.right_canvas_pop_bg.show {
  right: 0;
}
.right_canvas_body {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh;   */
  background: var(--bg-blue);
  border-radius: 15px 0 0 15px;
  animation: canvasPop_right 0.3s linear forwards;
}
.arrow_right {
  background: var(--bg-gray);
  display: flex;
  justify-content: center ;
  align-items: center;
  height: 5rem;
  width: 1.5rem;
  border-radius: 10px 0 0 10px;
  position: absolute;  
  top: 50%;
  left: -24px;
  transform: translateY(-50%);
}
.arrow_right img {
  transform: rotate(90deg);
  transition: transform 0.5s linear;
}
.active_arrow_right {
  transform: rotate(270deg) !important;
  transition: transform 0.5s linear;
}

.news-list{
  height: calc(100vh - 14rem);
  overflow-y: auto;
}
.news-list li{ 
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #F2C94C;
  margin-bottom: 20px;
}
.info-list {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 1fr;
}
.info-list li{
  background: url(../../../src/assets/images/list-icon.png) no-repeat 0 2px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-bottom: 10px;
  padding: 2px 0 2px 28px;
}
.top_canvas_pop_bg {
  width: 100%;
  display: flex;
  flex-direction: column ;
  top: 80px;
  background: transparent;
  align-items: center;
  z-index: 1;
  justify-content: center;
  position: fixed;
  transform: translateY(-90.2%);
  transition: transform .3s ease-in-out;
}
.top_canvas_pop_bg.show {
  transform: none;
}
.top_canvas_body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 10px;
  background: rgb(255, 255, 255, 0.8);  
}
.arrow_top {
  background: var(--bg-gray);
  display: flex;
  justify-content: center ;
  align-items: center;
  width: 5rem;
  height: 1.5rem;
  border-radius: 0 0 10px 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: transform 0.5s linear;
}
.active_arrow_top {
  transform: rotate(180deg) !important;
  transition: transform 0.5s linear;
}
.radial-graph-list{
  display: flex;
  list-style: none;
  gap: 60px;
  overflow-x: auto;
}
.radial-graph-list li{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.graph-box {
  width: 8.75rem;
}
.graph-content{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.graph-title{
  font-size: 20px;
  color: var(--bg-black);
  font-weight: bold;
}
.graph-subtext{
  font-size: 12px;
}
a.newsLink{font-weight: 300;font-size: 16px;line-height: 20px;color: #FAFCFE;margin-bottom: 20px; text-decoration: none; }
a.newsLink :hover{font-weight: 300;font-size: 16px;line-height: 20px;color: #FAFCFE;margin-bottom: 20px;}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .top_canvas_pop_bg {
    top: 100px;
  }
  .radial-graph-list {
    overflow: hidden;
  }
  /* .panel_content h2{
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 50px;
  } */
  .panel_content{
    padding: 30px 20px;
  }
  .news-list {
    height: calc(100vh - 30rem);
  }
  .left_canvas_pop_bg {
    width: 330px;
    left: -330px;
    top: 300px;
  }
  .left_canvas_body {
    /* height: 39.875rem; */
    border-radius: 0 30px 30px 0;
  }
  .basicinfo-list{
    height: calc(100vh - 20rem);
  }
  .basicinfoDshboard{
    height: calc(100vh - 34rem) !important;
  }
  .right_canvas_pop_bg{
    width: 330px;
    right: -330px;
    top: 300px;
  }
  .right_canvas_body {
    /* height: 39.875rem; */
    border-radius: 30px 0 0 30px;
  }
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

 }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 

 }